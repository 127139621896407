



















import Vue from 'vue';
import LoginByPassword from './loginByPassword.vue';
import LoginBySmsCode from './loginBySmsCode.vue';
export default Vue.extend({
  components: { LoginByPassword, LoginBySmsCode },
  data() {
    return {
      activeKey: '1',
      type: 'LoginByPassword',
    };
  },
  watch: {
    activeKey() {
      this.type = this.activeKey === '1' ? 'LoginByPassword' : 'LoginBySmsCode';
    },
  },
});
